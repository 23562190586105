/*global Waypoint: true */

(function ($, window, document, undefined) {
    'use strict';
    /**
     * App Widget
     */
    $.widget('nsm.waypointSteup', {

        /**
         * Widget Options
         */
        options: {},

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('waypoinoptions'));
            this.isRandomised = false;

            var waypointWidget = this;

            this.element.on('initialiseWaypoints', function(event) {
                var $el = $(event.target);
                event.stopImmediatePropagation();
                waypointWidget._initWidgets($el);
            });

            var eventHandlers = {};
            this._on(eventHandlers);
        },

        /**
         * Init widets
         *
         * @private
         */

        _initWidgets: function ($el) {
            var careerDetailWaypoint,
                SummaryIndexWrapper,
                SummaryIndexFooter;


            // Career Detail Sticky Nav
            if ($el.find('.CareerDetailToolbarWrapper').length) {
                careerDetailWaypoint = new Waypoint({
                    element: $el.find('.CareerDetailVideoPositioner')[0],
                    handler: function(direction) {
                        if (direction === 'down') {
                            $('.CareerDetailToolbarWrapper').addClass('is-fixed');
                        } else {
                            $('.CareerDetailToolbarWrapper').removeClass('is-fixed');
                        }
                    },
                    offset:  function() {
                        return $('html').hasClass('is-randomised') ? 262 : 150;
                    }
                });
            }

            // Highlight Career details current section
            $el.find('.js-CareerDetailSection').each(function() {
                new Waypoint({
                    element: $(this)[0],
                    handler: function() {
                        $('.js-CareerDetailNav a').removeClass('is-selected');
                        $('.js-CareerDetailNav a[href=#'+this.element.id+']').addClass('is-selected');
                    },
                    offset: 81
                });
            });

            if ($el.find('.SummaryIndexWrapper').length) {
                SummaryIndexWrapper = new Waypoint({
                    element: $el.find('.SummaryIndexWrapper')[0],
                    handler: function(direction) {
                        var $toolbar = $('.SummaryIndex-header .Toolbar');
                        var toolbarWidth = $toolbar.width();
                        if (direction === 'down') {
                            $toolbar.css({'width': toolbarWidth});
                            $toolbar.addClass('is-fixed');
                        } else {
                            $toolbar.css({'width': 'auto'});
                            $toolbar.removeClass('is-fixed');
                        }
                    },
                    offset:  function() {
                        return $('html').hasClass('is-randomised') ? 202 : 82;
                    }
                });
            }

            if ($el.find('.SummaryIndex-footer').length) {
                SummaryIndexFooter = new Waypoint({
                    element: $el.find('.SummaryIndex-footer')[0],
                    handler: function(direction) {
                        var $toolbar = $('.SummaryIndex-header .Toolbar');
                        var toolbarWidth = $toolbar.width();
                        if (direction === 'down') {
                            $toolbar.css({'width': 'auto'});
                            $toolbar.removeClass('is-fixed');
                        } else {
                            $toolbar.css({'width': toolbarWidth});
                            $toolbar.addClass('is-fixed');
                        }
                      },
                    offset: 0
                });
            }
        }
    });

})(jQuery, window, document);

(function ($, window, document, undefined) {
    'use strict';
    /**
     * Checkbox heiracrhcy widget
     */
    $.widget('nsm.tooltip', {

        /**
         * Widget Options
         */
        options: {
            triggerSelector: '.Tooltip-trigger',
            contentSelector: '.Tooltip-content'
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('filteroptions'));
            this.isOpen = this.element.hasClass('is-expanded');
            this.content = this.element.find(this.options.contentSelector);
            this.trigger = this.element.find(this.options.triggerSelector);

            var eventHandlers = {};
            eventHandlers['click' + this.options.triggerSelector] = '_showContent';
            this._on(eventHandlers);
        },

        _showContent: function (event) {
            event.stopPropagation();
            this.content.velocity('stop').velocity('transition.slideUpIn', {duration: 300});
            this.content.removeClass('is-hidden');

            this.trigger.addClass('is-expanded');
            // Can't use ._on as the corresponding _off method unbinds all handlers.

            $('body').on('click',  {widget: this}, this._testHit);
        },

        _testHit: function(event) {
            var widget = event.data.widget;
            if ($(event.target).closest(widget.element).length < 1) {
                widget.content.fadeOut(200, function() {
                    widget.content.addClass('is-hidden');
                    widget.trigger.removeClass('is-expanded');
                });
                $('body').off('click', this._testHit);
            }
        }

    });

})($, window, document, undefined);

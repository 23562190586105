/*global Waypoint: true */

(function ($, window, document, ga, undefined) {
    'use strict';
    /**
     * Tabs Widget
     */
    $.widget('nsm.featuredCareers', {

        /**
         * Widget Options
         */
        options: {
            selectors: {
                transplantHolder: '.CareerSummaryIndex',
                allCareers : '.CareerSummary',
                transplantSelector : '.CareerSummary:lt(3)',
                titleSelector : '.CareerIndexTitle'
            },
            classes: {

            }
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('appoptions'));

            var featuredCareerWidget = this;

            this.title =  $(this.options.selectors.titleSelector);

            this.element.on('removeFeaturedCareers', function() {
                featuredCareerWidget._remove();
            });

            this.resultCount = $(this.options.selectors.allCareers).length;

            if (this.resultCount > 3) {
                this._populate();
            }
        },

        _populate: function() {
            var copiedResults = $(this.options.selectors.transplantSelector);
            this.element.find(this.options.selectors.transplantHolder).append(copiedResults);
            this.title.fadeIn(500);
            this.element.fadeIn(500);
            Waypoint.refreshAll();

        },

        /**
         * Remove the Block
         *
         * @param event
         * @private
         */
        _remove: function () {
            this.element.fadeOut();
            this.title.fadeOut();
            Waypoint.refreshAll();
        }


    });

})(jQuery, window, document, window.ga);

/*global jQuery:true, window:true, document:true, Waypoint:true, globals:true, docCookies:true, ga: true */

(function ($, window, document, undefined) {
    'use strict';
    /**
     * App Widget
     */
    $.widget('nsm.app', {

        /**
         * Widget Options
         */
        options: {},

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('appoptions'));

            var appWidget = this;

            this.element.on('contentcreated', function(event) {
                var $el = $(event.target);
                event.stopImmediatePropagation();
                appWidget._initWidgets($el);
            });

            this.element.trigger('contentcreated');

            // Register global app events
            var eventHandlers = {};
            this._on(eventHandlers);

            $('html').randomCareer();
        },

        /**
         * Init widets
         *
         * @private
         */
        _initWidgets: function ($el) {
            this.element.trigger('initialiseWaypoints');

            $el.find('.js-setCareerOrder').change(function() {
                $('.js-orderByField').attr('value', this.value);
                $('.js-AjaxResults').trigger('submitSearch');
            });

            $el.find('.js-viewToggle').click(function() {
                var newMode = $(this).data('view');
                $('.js-viewToggle').removeClass('is-selected');
                $(this).addClass('is-selected');
                var $container = $(this).closest('.js-AjaxResults');
                if (newMode === 'list') {
                    globals.searchResultViewMode = 'list';
                    docCookies.setItem('searchResultViewMode', 'list', Infinity, '/');
                    $container.find('.CareerSummaryIndex').removeClass('CareerSummaryIndex--cardIndex').addClass('CareerSummaryIndex--listIndex');
                } else if (newMode === 'grid') {
                    globals.searchResultViewMode = 'grid';
                    docCookies.setItem('searchResultViewMode', 'grid', Infinity, '/');
                    $container.find('.CareerSummaryIndex').removeClass('CareerSummaryIndex--listIndex').addClass('CareerSummaryIndex--cardIndex');
                }
                Waypoint.refreshAll();
            });


            $el.find('.js-void').click(function (e) {
                e.preventDefault();
            });

            $el.find('.js-ajaxLoader').ajaxResults();
            $el.find('.js-widget-ajaxLoad').ajaxLoad();

            $el.find('.js-accordionWrapper').accordion();
            $el.find('.js-nestedCheckboxes').subFilters();
            $el.find('.js-tabSystem').tabSwitcher();

            $el.find('.js-sharingLinks').sharingPane();

            $el.find('.js-carousel').slick();
            $el.find('.js-fitVids').fitVids();
            $el.find('.js-tooltip').tooltip();
            $el.find('.js-shuffleButtons').shuffleButtons();
            $el.find('.js-smoothScroller').smoothScroller();

            $el.find('.js-CareerCategoryFilter').careerCategoryFilter();
            $el.find('.js-AjaxResults').dataGrid();
            $el.find('.js-featuredCareerIndex').featuredCareers();
            $el.find('.js-showOverlay').mbtOverlay();
            $el.find('.js-trackClassClick').trackClassClick();
            $el.find('.js-jwVideo').jwVideo();

            // bind the entity search functionality to the global search bar
            $el.find('.js-searchForm').searchAutocomplete();

            // trigger a Google Tag Manager Event on search results page load
            $el.find('.CareerSummaryIndex').each(function() {
                // check parent and if its not a search exit
                if (0 === $(this).parents('#CareerSearchResults').length) {

                    return;
                }

                // prep the variables
                var jqSelf = $(this),
                    jqSidebar = $('#sidebar-search'),
                    jqCategories = jqSidebar.find('[name="category\\[\\]"]:checked'),
                    searchTerms = encodeURIComponent(jqSidebar.find('.SidebarSearch-inputField').val()),
                    jqSummaryIndex = jqSelf.parents('.SummaryIndex').eq(0),
                    discoverSearchResults = ('y' === jqSummaryIndex.find('[name="discover"]').val()),
                    searchCategories = [];

                // discover page submission? note it
                if (true === discoverSearchResults) {
                    searchTerms = 'DISCOVER';
                }

                jqCategories.each(function() {
                    var jqCheckbox = $(this),
                        thisId = jqCheckbox.attr('id'),
                        jqLabel = jqSidebar.find('[for="' + thisId + '"]'),
                        categoryName = (jqLabel.text() || '').trim();

                    if ('' !== categoryName) {
                        searchCategories.push(categoryName);
                    }
                });

                ga('send', 'pageview', '/search?q=' + searchTerms + '&c=' + searchCategories.join(','));
            });

            // Rel='external' links open in a new tab
            $el.find('a[rel="external"]').on('click', function (e) {
                e.preventDefault();
                var url = $(this).attr('href');
                window.open(url, '_blank');
            });

        }
    });

})(jQuery, window, document);

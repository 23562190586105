(function ($, window, document, undefined) {
    // Tabs Widget
    'use strict';

    $.widget('nsm.tabSwitcher', {

        options: {
            triggerSelector: '.Tab-link',
            triggerActiveClass: 'is-active',
            targetinActiveClass: 'is-active'
        },

        _create: function () {

            var _this = this;

            this.options = $.extend(this.options, this.element.data('taboptions'));

            this.triggers = this.element.find(this.options.triggerSelector);
            this.targets = $();

            this.triggers.each(function(){
                var $this = $(this);
                var targetSelector = $this.prop('hash');
                _this.targets = _this.targets.add($(targetSelector));
            });

            var eventHandlers = {};
            eventHandlers['click ' + this.options.triggerSelector] = '_captureClick';
            this._on(eventHandlers);

            this.activeTrigger = this.triggers.eq(0);
            this.activeTrigger.trigger('show');
        },


        _captureClick: function (event) {
            event.preventDefault();
            this.activeTrigger = $(event.currentTarget);
            this.showCurrentTab();
        },

        showCurrentTab: function() {
            var targetSelector = this.activeTrigger.prop('hash');

            this.target = $(targetSelector);
            this.triggers.not('[href='+targetSelector+']').removeClass(this.options.triggerActiveClass);
            this.triggers.filter('[href='+targetSelector+']').addClass(this.options.triggerActiveClass);

            if ($(window).width() < 768) {
                this.targets.not(this.target).velocity('slideUp', { 'duration': 1000, 'easing': 'easeInOutQuart'});
                this.target.velocity('slideDown', { 'duration': 1000, 'easing': 'easeInOutQuart'});
                setTimeout($.proxy(this._scrollToTab, this), 1000);
            } else {
                this.targets.not(this.target).hide();
                this.target.show();
            }
        },

        _scrollToTab: function() {
            var newTopPos = (this.target.offset().top - 260) + 'px';
            $('html').velocity('scroll', {'offset': newTopPos, 'duration': 1000, 'easing': 'easeInOutQuart'});
        }

    });

})(jQuery, window, document);

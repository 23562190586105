/*global globalCareerList: true */

(function ($, window, document, ga, undefined) {
    'use strict';
    /**
     * Tabs Widget
     */
    $.widget('nsm.careerCategoryFilter', {

        /**
         * Widget Options
         */
        options: {
            settings: {
                scrollDuration: 1000
            },
            selectors: {
                input: '.CareerDiscover-buttonInput',
                badge: '.CareerFilterToolbar-badge',
                badgeCount: '.js-CareerFilterCount',
                formActionBar: '.CareerDiscover-desktopFilters'
            },
            classes: {

            }
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('options'));
            this.$badge = $(this.options.selectors.badge);
            this.$badgeCount = $(this.options.selectors.badgeCount);
            this.hasScrolled = false;

            this.careers = globalCareerList.careers;
            this.totalCareersCount =this.careers.length;

            var eventHandlers = {};
            eventHandlers['change ' + this.options.selectors.input] = '_handleFormChange';
            this._on(eventHandlers);
        },

        _init: function() {
            this.$badgeCount.text(this.totalCareersCount);
            this.$badge.removeClass('is-hidden');
        },

        /**
         * Process the search form
         *
         * @param event
         * @private
         */
        _handleFormChange: function (event) {
            //event.preventDefault();
            if (!this.hasScrolled) {
                this._scrollToSubmit();
            }
            var $input = $(event.currentTarget);
            var $wrapper = $input.parent();
            console.log(event.currentTarget);
            var isChecked = $input.prop('checked');
            $wrapper.toggleClass('is-selected', isChecked);

            this._update();
        },

        _update: function() {
            this.matchCount = 0;
            var categoryIds = [];

            $('[name="category[]"]:checked').each(function() {
                categoryIds.push(parseInt(this.value));
            });


            for (var i = 0; i < this.totalCareersCount; i++) {
                var career = this.careers[i];

                var matchesCategories = this._testArrayOverlap(career.categories, categoryIds);

                if (matchesCategories) {
                    this.matchCount++;
                }

            }

            this.$badgeCount.text(this.matchCount);
        },

        // Test the array.
        // If any id from a careers catgegory id array matches an id in the checked input array - return true.
        // If the checked input array is empty - retrurn true as there is no filter
        // else return false

        _scrollToSubmit: function() {
            this.hasScrolled = true;
            var $actionBar = $(this.options.selectors.formActionBar);
            var actionBarPosition = $actionBar.position();
            var actionBarPositionTop = actionBarPosition.top;
            var newYPos = actionBarPositionTop - $(window).height() + 190;

            if (($(document).scrollTop() + $(window).height()) < actionBarPositionTop) {
                $('html').velocity('scroll', {'offset': newYPos, 'duration': this.options.settings.scrollDuration, 'easing': 'easeInOutQuart'});
            }

        },

        _testArrayOverlap: function(valueArray, inputArray) {
            var match = false;
            if (inputArray.length === 0) {
                match = true;
            } else {
                for (var i = 0; i < valueArray.length; i++) {
                    if ($.inArray(valueArray[i], inputArray) !== -1) {
                        match = true;
                        break;
                    }
                }
            }

            return match;
        }

    });

})(jQuery, window, document, window.ga);

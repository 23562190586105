(function ($, window, document, undefined) {
    'use strict';
    /**
     * Checkbox heiracrhcy widget
     */
    $.widget('nsm.smoothScroller', {

        /**
         * Widget Options
         */
        options: {
            duration: 5000,
            offset: 80
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('filteroptions'));

            var eventHandlers = {};
            eventHandlers.click = '_scrollTo';
            this._on(eventHandlers);
        },

        _scrollTo: function (event) {
            event.preventDefault();
            var hash = $(this.element)[0].hash;
            var target = $(hash);
            var pos = (target.offset().top - this.options.offset);
            pos = (pos > $(window).scrollTop()) ? pos + 5: pos - 5;
            pos = pos + 'px';
            if (target.length) {
                $('html').velocity('stop').velocity('scroll', {'offset': pos, 'duration': 1250, 'easing': 'easeInOutQuart'});
            }
        }

    });

})($, window, document, undefined);

/*global docCookies: true, jwplayer:true */

(function ($, window, document, undefined) {
    // Tabs Widget
    'use strict';

    $.widget('nsm.jwVideo', {

        options: {
            baseUrl: 'https://downloads.newcastle.edu.au/mbt/',
            videoFilename: null,
            videoTitle: ''
        },

        _create: function () {
            //var overlayWidget = this;
            this.options = $.extend(this.options, this.element.data('video-options'));

            if (this.options.videoFilename) {
                var playerInstance = jwplayer(this.element.prop('id'));

                playerInstance.setup({
                    file: this.options.baseUrl + this.options.videoFilename,
                    image: this.options.videoPoster,
                    width: '100%',
                    aspectratio: '16:9',
                    title: this.options.videoTitle,
                    autostart: false,
                    skin: {
                        name:'seven',
                        active: '#FF6D00',
                        inactive: '#00A3C1',
                        background: '#233036'
                    },
                    ga: {}
                });
            }
        }

    });

})(jQuery, window, document);

(function ($, window, document, undefined) {

    'use strict';
    /**
     * Checkbox heiracrhcy widget
     */
    $.widget('nsm.accordion', {

        /**
         * Widget Options
         */
        options: {
            clipperSelector: '.Accordion-clipper',
            toggleSelector: '.js-accordionToggle',
            duration: 500
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('filteroptions'));
            this.isOpen = this.element.hasClass('is-expanded');
            this.clipper = this.element.find(this.options.clipperSelector);

            var eventHandlers = {};
            eventHandlers['click' + this.options.toggleSelector] = '_toggleState';
            this._on(eventHandlers);
        },

        _toggleState: function () {
            if (this.isOpen) {
                this._closeAccordion();
            } else {
                this._openAccordion();
            }
        },

        _openAccordion: function () {
            var $widgetElem = this.element;
            this.clipper.slideDown(this.options.duration, function() {
                $widgetElem.addClass('is-expanded');
            });
            this.isOpen = true;
        },

        _closeAccordion: function () {
            var $widgetElem = this.element;
            this.clipper.slideUp(this.options.duration, function() {
                $widgetElem.removeClass('is-expanded');
            });
            this.isOpen = false;
        },

        open: function() {
            this._openAccordion();
        },

        close: function() {
            this._closeAccordion();
        }
    });

})($, window, document, undefined);

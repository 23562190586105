/*global jQuery: true */
(function ($, window, document, undefined) {
    'use strict';

    var resultsXhr = null;

    /**
     * Tabs Widget
     */
    $.widget('cp.ajaxResults', {

        /**
         * Widget Options
         */
        options: {
            display: '.js-productCollection',
            searchForm: '.js-ajaxSearchForm',
            loadMoreButton: '.js-loadMoreResultsButton',
            loadMoreWrapper: '.js-loadMoreResultsWrapper',
            resultWrapper: '.js-ajaxResultCollection',
            submitButton: '.js-ajaxSearchFormSubmit',
            noticeLabel: '.js-ajaxLoaderNotice',
            resetFormButton: '.js-clearAllFormData',
            resetGroupButton: '.js-clearFilterGroup',
            toggleFilterButton: '.js-FilterGroupToggleLabel, .js-FilterGroupToggle',
            fetchingData: false
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {

            this.options = $.extend(this.options, this.element.data('options'));
            var eventHandlers = {};

            eventHandlers['change' + this.options.searchForm + ' input'] = '_sendQuery';
            eventHandlers['change' + this.options.searchForm + ' select'] = '_sendQuery';
            eventHandlers['submit' + this.options.searchForm] = '_sendQuery';
            eventHandlers['click' + this.options.loadMoreButton] = '_fetchNextPage';
            eventHandlers['click' + this.options.toggleFilterButton] = '_toggleFilterVisibility';
            eventHandlers['click' + this.options.resetGroupButton] = '_clearFilter';
            eventHandlers['click' + this.options.resetFormButton] = '_clearAllFilters';
            this._on(eventHandlers);

            this.selects = $('.js-selectize--filter').selectize({
                dropdownParent: 'body',
                preload: 'focus'
            });

        },

        _checkRequest: function () {
            this._showLoadingOverlay();

            return true;
        },

        _sendQuery: function () {
            var form = $(this.options.searchForm),
                widget = this,
                successHandlerCallback = function(responseText) {
                    resultsXhr = null;
                    widget.options.fetchingData = false;

                    return widget._replaceResults(responseText);  // post-submit callback
                },
                xhrOptions = {
                    url: form.attr('action'),
                    data: form.serializeArray(),
                    type: 'POST',
                    dataType: 'json',
                    beforeSend: function() {
                        widget.options.fetchingData = true;

                        return widget._checkRequest(); // pre-submit callback
                    },
                    success: function(data) {

                        return successHandlerCallback(data);  // post-submit callback
                    },
                    error: function(jqXHR) {
                        if ('OK' === jqXHR.statusText) {

                            return successHandlerCallback(jqXHR.responseText);  // post-submit callback
                        }

                        return false;
                    }
                };

            if (null !== resultsXhr) {
                resultsXhr.abort();
                resultsXhr = null;
                widget.options.fetchingData = false;
            }

            resultsXhr = $.ajax(xhrOptions);

            return false;
        },

        _fetchNextPage: function (event) {
            var widget = this;

            event.preventDefault();
            if (!this.options.fetchingData) {

                if (null !== resultsXhr) {
                    resultsXhr.abort();
                    resultsXhr = null;
                    widget.options.fetchingData = false;
                }

                $(this.options.loadMoreWrapper).fadeTo(500, 0.5);

                resultsXhr = $.ajax({
                    url: $(this.options.loadMoreButton).attr('href')
                }).success(function (data, statusText, jqXHR) {
                    widget._appendResults(data, statusText, jqXHR);
                    widget.options.fetchingData = false;

                    resultsXhr = null;
                });

                this._showLoadingOverlay();
                this.options.fetchingData = true;
            }
        },

        _replaceResults: function (responseText) {
            var $response = $(responseText),
                $results = $response.find(this.options.resultWrapper).children();

            $(this.options.resultWrapper).empty();
            $results.hide();
            $results.appendTo(this.options.resultWrapper).fadeIn(800);
            $(this.options.resultWrapper).trigger('contentcreated');
            this._updateLoadMoreButton($response);
            this._hideLoadingOverlay();
        },

        _appendResults: function (responseText) {
            var widget = this,
                $response = $(responseText),
                $resultWrapper,
                $results;

            $resultWrapper = $response.find(this.options.resultWrapper);
            $results = $resultWrapper.children();
            $results.find('.ProductEntry').addClass('is-collapsed').addClass('is-animated');
            $results.appendTo(this.options.resultWrapper).fadeIn(800);
            $(this.options.resultWrapper).trigger('contentcreated');
            this._updateLoadMoreButton($response);
            setTimeout(function () {
                $results.find('.ProductEntry').removeClass('is-collapsed');
                widget._hideLoadingOverlay();
            }, 200);
        },

        _updateLoadMoreButton: function (response) {
            // Remove the previous load more
            this.element.find(this.options.loadMoreWrapper).remove();

            // find the new load more and insert it after the results
            $(response).find(this.options.loadMoreWrapper).insertAfter(this.options.resultWrapper);
        },

        _showLoadingOverlay: function () {
            $(this.options.resultWrapper).fadeTo(300, 0.5);
            $(this.options.noticeLabel).fadeIn(300);
        },

        _hideLoadingOverlay: function () {
            $(this.options.resultWrapper).fadeTo(300, 1);
            $(this.options.noticeLabel).fadeOut(300);
        },

        _clearFilter: function (event) {
            var $group,
                $selects;

            event.preventDefault();
            $group = $(event.target).closest('.FilterGroup');
            $group.find('input[type="checkbox"]').prop('checked', false);
            $group.find('input[type="text"]').val('');
            $group.find('input[type="search"]').val('');
            $group.find('select option:eq(1)').prop('selected', true);
            $selects = $group.find('select.js-selectize--filter');

            $selects.each(function () {
                var control = $(this)[0].selectize;
                control.clear();
            });

            this._sendQuery();
        },

        _clearAllFilters: function (event) {
            var $form,
                $selects;

            event.preventDefault();
            $form = $(event.target).closest('form');
            $form.find('input[type="checkbox"]').prop('checked', false);
            $form.find('input[type="text"]').val('');
            $form.find('input[type="search"]').val('');
            $selects = $form.find('select.js-selectize--filter');

            $selects.each(function () {
                var control = $(this)[0].selectize;
                control.clear();
            });

            this._sendQuery();
        },

        _toggleFilterVisibility: function (event) {
            var $group;

            event.preventDefault();
            $group = $(event.target).closest('.FilterGroup');
            $group.find('.js-FilterGroupToggle, .FilterGroup-header, .js-FilterGroupContainer').toggleClass('is-expanded');
        }

    });

})(jQuery, window, document);

(function ($, window, document, ga, undefined) {
    'use strict';
    /**
     * Checkbox heiracrhcy widget
     */
    $.widget('cp.subFilters', {

        /**
         * Widget Options
         */
        options: {
            duration: 400
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('filteroptions'));

            this._initialState();

            var eventHandlers = {};
            eventHandlers['click label'] = '_testObject';
            this._on(eventHandlers);
        },

        /**
         * Test the checkboxes
         *
         * @param event
         * @private
         */

        _initialState: function () {
            var localWidget = this;
            this.element.find('ul').each(function() {
                var $list = $(this);
                if (localWidget._isMixedMode($list)) {
                    $list.parent().addClass('is-mixedMode');
                }
                if (localWidget._isMixedMode($list) || $list.parent().find('> input[type="checkbox"]:checked').length > 0) {
                    $list.addClass('is-expanded');
                }
            });
        },

        _testObject: function (event) {
            event.preventDefault();
            var $label = $(event.target);
            var $currentBox = $label.prev();
            this._testCheckbox($currentBox);
        },

        _openList: function ($list) {
            //$list.addClass('is-expanded');
            $list.slideDown(this.options.duration, function() {
               $list.addClass('is-expanded');
            });
        },

        _closeList: function ($list) {
            $list.parent().removeClass('is-mixedMode');

            $list.slideUp(this.options.duration, function() {
               $list.removeClass('is-expanded');
            });

            $list.find('input[type=checkbox]').prop('checked', false);
            $list.find('.is-mixedMode').removeClass('is-mixedMode');
            $list.find('.is-expanded').removeClass('is-expanded');
        },


        _testCheckbox: function ($currentBox) {
            var $parentList = $currentBox.parent().parent();
            var $childList = $currentBox.parent().find('> ul');

            if ($currentBox.prop('checked')) {
                $currentBox.prop('checked', false);
                this._closeList($childList);
            } else {
                if (this._isMixedMode($childList)) {
                    $currentBox.prop('checked', false);
                    this._closeList($childList);
                } else {
                    $currentBox.prop('checked', true);
                    this._openList($childList);
                }
            }
            $currentBox.trigger('change');
            this._testList($parentList);
        },

        _testList: function ($list) {
            var $parentBox = $list.parent().find('> input[type=checkbox]');
            var $listItem = $list.parent();

            if (this._isMixedMode($list)) {
                $listItem.addClass('is-mixedMode');
                $parentBox.prop('checked', false);
                $parentBox.trigger('change');
            } else {
                $listItem.removeClass('is-mixedMode');
                $parentBox.prop('checked', true);
                $parentBox.trigger('change');
            }

        },

        _isMixedMode: function ($list) {
            var $checkboxes = $list.find('li input[type=checkbox]');
            var checkboxCount = $checkboxes.length;
            var checkedCheckoxCount = $checkboxes.filter(':checked').length;
            var isMixed = (checkedCheckoxCount !== checkboxCount && checkedCheckoxCount !== 0);
            return isMixed;
        }

    });

})(jQuery, window, document, window.ga);

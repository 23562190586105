/*global ga: true */

(function ($, window, document, undefined) {
    'use strict';
    /**
     * App Widget
     */
    $.widget('nsm.trackClassClick', {

        /**
         * Widget Options
         */
        options: {},

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('waypoinoptions'));

            var eventHandlers = {};
            eventHandlers.click = '_captureClick';
            this._on(eventHandlers);
        },

        /**
         * Init widets
         *
         * @private
         */

        _captureClick: function (event) {
            var chosenTitle;

            event.preventDefault();
            chosenTitle = $(event.target).attr('title');

            ga('send', {
                'hitType': 'event', // Required.
                'eventCategory': 'Classwork', // Required.
                'eventAction': 'Click', // Required.
                'eventLabel': chosenTitle
            });
        }
    });

})(jQuery, window, document);

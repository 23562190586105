(function ($, window, document, ga, undefined) {
    'use strict';
    /**
     * Tabs Widget
     */
    $.widget('nsm.shuffleButtons', {

        /**
         * Widget Options
         */
        options: {
            interestSelecrtor: '[data-button-type="interest"]',
            subjectSelector: '[data-button-type="subject"]',
            environmentSelecrtor: '[data-button-type="environment"]'
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.$interests = this.element.find(this.options.interestSelecrtor);
            this.$subjects = this.element.find(this.options.subjectSelector);
            this.$environments = this.element.find(this.options.environmentSelecrtor);
            this.loopCount = Math.max(this.$interests.length, this.$subjects.length, this.$environments.length);
            this._shuffle();

        },

        /**
         * Process the search form
         *
         * @param event
         * @private
         */
        _shuffle: function () {
            this.$interests.detach();
            this.$subjects.detach();
            this.$environments.detach();
            for(var i=0; i < this.loopCount; i++) {
                this.element.append(this.$interests[i]);
                this.element.append(this.$subjects[i]);
                this.element.append(this.$environments[i]);
            }
            this.element.fadeIn(600);
        }

    });

})(jQuery, window, document, window.ga);

/*global docCookies: true, jwplayer:true */

(function ($, window, document, undefined) {
    // Tabs Widget
    'use strict';

    $.widget('nsm.mbtOverlay', {

        options: {
            baseUrl: 'https://downloads.newcastle.edu.au/mbt/',
            content:'',
            showHomepagePrompt: false,
            videoFilename: null,
            videoTitle: '',
            selectors: {
                closeButton: '.js-closeModal',
                modalBackground: '.ModalBackground',
                modals: '.FullsceenOverlay',
                siteWrapper: '.SiteContentWrapper',
                navbar: '.SiteHeader-bar',
                modalbar: '.SiteHeader-ModalBar',
                backPrompt: '.SiteLogo-backPrompt',
                videoContainer: '.VideoOverlay-container'
            }

        },

        settings: {
            pageOffset: 0
        },

        _create: function () {
            //var overlayWidget = this;
            this.firstPlay = true;
            this.options = $.extend(this.options, this.element.data('overlayoptions'));
            this.modalBackground = $(this.options.selectors.modalBackground);
            this.closeButton = $(this.options.selectors.closeButton);
            this.siteWrapper = $(this.options.selectors.siteWrapper);
            this.navbar = $(this.options.selectors.navbar);
            this.modalbar = $(this.options.selectors.modalbar);
            this.backPrompt = $(this.options.selectors.backPrompt);
            this.content = $(this.options.content);
            this.videoContainer = $(this.options.selectors.videoContainer);
            this.pauseFlag = false;

            var eventHandlers = {};
            eventHandlers.click = '_openModal';
            //eventHandlers['click ' + this.options.selectors.modalBackground] = '_closeModal';
            this._on(eventHandlers);

            this.closeButton.on('click', $.proxy(this._closeModal, this));
            //this.modalBackground.on('click', $.proxy(this._closeModal, this));
        },

        _fadeInContent: function() {
            $(window).scrollTop(0);
            this.siteWrapper.addClass('is-scrollLocked');
            this.content.velocity('stop').velocity('fadeIn', {duration: 300, complete: $.proxy(this._setSearchFocus, this)});

            if (this.options.videoFilename) {

                this.videoWrapper = $('<div/>', {
                    'id': 'player',
                    'class': 'VideoOverlay-video'
                });

                // this.videoContainer.hide();
                this.videoContainer.show();
                this.videoWrapper.appendTo(this.videoContainer);

                var playerInstance = jwplayer('player');

                playerInstance.setup({
                    file: this.options.baseUrl + this.options.videoFilename,
                    image: this.options.videoPoster,
                    width: '100%',
                    aspectratio: '16:9',
                    title: this.options.videoTitle,
                    autostart: true,
                    skin: {
                        name:'seven',
                        active: '#FF6D00',
                        inactive: '#00A3C1',
                        background: '#233036'
                    },
                    tracks: [{
                        file: this.options.baseUrl + this.options.videoCaptions,
                        label: 'English',
                        kind: 'captions',
                        'default': true
                    }],
                    ga: {}
                });

            }

            if (this.options.showHomepagePrompt) {
                this.backPrompt.addClass('is-visible');
                $('.js-Breadcrumbtext').hide();
            }

        },

        _setSearchFocus: function() {
          if (this.options.content === '#SearchOverlay') {
            $('.SearchOverlay-keywordInput').focus();
          }
        },

        _openModal: function(event) {
            //empty the modal
            event.preventDefault();

            $('html').trigger('closeCareeramajig');
            this.navbar.hide();
            this.modalbar.show();
            this.settings.pageOffset = $(window).scrollTop();

            if (this.modalBackground.is(':visible')) {
                // A modal is visible, hide the current one and then run the content fade in.
                $(this.options.selectors.modals + ':visible').velocity('stop').velocity('fadeOut', {duration: 300});
                this._fadeInContent();
            } else {
                // No modal visible, fade in the background, and when thats finished, fade in the content.
                this.modalBackground.velocity('stop').velocity('fadeIn', {duration: 300, complete: $.proxy(this._fadeInContent, this)});
            }
        },

        _closeModal: function() {
            this.modalbar.hide();
            this.navbar.show();
            if(docCookies.getItem('showCareeramajig') === 'true') {
                $('html').trigger('openCareeramajig');
            }
            this.siteWrapper.removeClass('is-scrollLocked');
            this.modalBackground.velocity('stop').velocity('fadeOut', {duration: 500});
            this.content.velocity('stop').velocity('fadeOut', {duration: 300});
            $('#player').remove();

            $(window).scrollTop(this.settings.pageOffset);
            this.backPrompt.removeClass('is-visible');
            $('.js-Breadcrumbtext').show();
        }

    });

})(jQuery, window, document);

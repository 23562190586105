/*global Waypoint: true, globals: true, docCookies: true */

(function ($, window, document, history, undefined) {
    'use strict';

    if(window.history === 'undefined') {
        window.history = $.noop;
    }

    /**
     * DatGrid Widget
     */
    $.widget('nsm.dataGrid', {

        /**
         * Options
         */
        options: {
            classes: {
                loading: 'is-loading'
            },
            selectors: {
                links: '.js-AjaxResults-pagination a',
                searchForm: '.js-AjaxResults-search',
                results: '.js-AjaxResults-display',
                searchButton: '.js-AjaxResults-submit',
                liveInput: '.js-AjaxResults-liveInput',
                record: '.CareerSummary',
                searchSummaryWrapper: '.SearchResultsCtaWrapper',
                searchSummaryCount: '.SearchResultCta-titleCount',
                searchSummaryKeywords: '.SearchResultCta-titleQueryKeyword',
                loadingIndicator: '.AjaxSearchResults-loadingIndicator',
                keywordWrapper: '.SearchResultsCtaWrapper',
                keywordField: '.SidebarSearch-inputField',
                orderByField: '.js-orderByField',
                orderSelect: '.js-setCareerOrder'
            }
        },

        /**
         * Create the widget
         *
         * @private
         */
        _create: function () {

            var widget = this,
                eventHandlers;

            widget.options = $.extend(widget.options, widget.element.data('dataGridOptions'));

            this.jqXhr = null;
            this.ajaxIsSending = false;

            this.resultsEl = this.element.find(this.options.selectors.results);
            this.searchButton = this.element.find(this.options.selectors.searchButton);
            this.searchForm = this.element.find(this.options.selectors.searchForm);
            this.keywordWrapper = this.element.find(this.options.selectors.keywordWrapper);

            this.searchSummaryWrapper = this.element.find(this.options.selectors.searchSummaryWrapper);
            this.searchSummaryCount = this.element.find(this.options.selectors.searchSummaryCount);
            this.searchSummaryKeywords = this.element.find(this.options.selectors.searchSummaryKeywords);
            this.records = this.element.find(this.options.selectors.record);
            this.loadingIndicator = this.element.find(this.options.selectors.loadingIndicator);
            this.keywordField = this.element.find(this.options.selectors.keywordField);
            this.orderByField = this.element.find(this.options.selectors.orderByField);
            this.resultCount = parseInt(this.element.find('[name="resultCount"]').first().val());
            this.orderSelect = this.element.find(this.options.selectors.orderSelect);

            eventHandlers = {};
            eventHandlers['click ' + this.options.selectors.links] = '_handleFilterLink';
            eventHandlers['submit ' + this.options.selectors.searchForm] = '_submitForm';
            eventHandlers['change ' + this.options.selectors.liveInput] = '_handleFilterForm';


            this.element.on('submitSearch', function() {
                widget._handleFilterForm();
            });

            // pjax events if pjax is enabled
            // eventHandlers['pjax:beforeSend'] = '_beforeSend';
            // eventHandlers['pjax:complete'] = '_complete';

            this._on(eventHandlers);

            this.searchButton.hide();

            this._setViewMode(this.element);
            this._keywordBarTest();
        },

        /**
         * Handle filter link click
         *
         * @param event
         * @private
         */
        _submitForm: function (event) {
            event.preventDefault();
            this._handleFilterForm();
        },

        _handleFilterLink: function (event) {

            // if ($.support.pjax) {
            //     $.pjax.click(event, {
            //         container: this.options.selectors.results,
            //         fragment: this.options.selectors.results
            //     });
            //     return;
            // }

            event.preventDefault();

            var $link = $(event.currentTarget),
                url = $link.prop('href');

            if (this.ajaxIsSending) {
                this.jqXhr.abort();
            }

            this.ajaxIsSending = true;

            this.jqXhr = $.ajax({
                type: 'get',
                url: url,
                beforeSend: $.proxy(this._beforeSend, this),
                error: $.proxy(this._error, this),
                success: $.proxy(this._success, this),
                complete: $.proxy(this._complete, this)
            });


        },

        /**
         * Handle filter form submit
         *
         * @param event
         * @private
         */
        _handleFilterForm: function () {
            // if ($.support.pjax) {

            //     $.pjax.submit(event, {
            //         container: this.options.selectors.results,
            //         fragment: this.options.selectors.results
            //     });
            //     return;

            // }



            if (this.ajaxIsSending) {
                this.jqXhr.abort();
            }

            // if (this.keywordField.val() !== '') {
            //     this.orderByField.val('low_search_score|asc');
            // }

            var $form = this.searchForm,
                formData = this.searchForm.serializeArray();

            this.ajaxIsSending = true;

            this.jqXhr = $.ajax({
                type: $form.prop('method') || 'POST',
                url: $form.prop('action'),
                data: formData,
                beforeSend: $.proxy(this._beforeSend, this),
                error: $.proxy(this._error, this),
                success: $.proxy(this._success, this),
                complete: $.proxy(this._complete, this)
            });
        },

        /**
         * Fired before send
         *
         * @private
         */
        _beforeSend: function () {
            var currentLoadingIndicator = this.loadingIndicator;
            var containerPosition = this.element.position();
            var topPos = (containerPosition.top - 50) + 'px';

            $('html').velocity('stop', true).velocity('scroll', {offset: topPos, duration: 1200, easing: 'easeInOutQuart'});

            this.resultsEl.velocity('stop', true).velocity({
                opacity: 0.5
                }, {
                complete: function() {
                    currentLoadingIndicator.velocity('stop', true).velocity('transition.bounceIn', { duration: 500 });
                }
            });
        },

        /**
         * Fired on Error
         *
         * @private
         */
        _error: function () {
        },

        _setViewMode: function(inputHtml) {
            var viewmode;
            var loadedMode = docCookies.getItem('searchResultViewMode');

            if ('searchResultViewMode' in globals) {
                viewmode = globals.searchResultViewMode;
            } else if (loadedMode) {
                viewmode = loadedMode;
            } else {
                viewmode = 'list';
            }

            if (viewmode === 'list') {
                inputHtml.find('.CareerSummaryIndex').removeClass('CareerSummaryIndex--cardIndex').addClass('CareerSummaryIndex--listIndex');
                inputHtml.find('.js-viewToggle[data-view="list"]').addClass('is-selected');
                inputHtml.find('.js-viewToggle[data-view="grid"]').removeClass('is-selected');
            }

            if (viewmode === 'grid') {
                inputHtml.find('.CareerSummaryIndex').removeClass('CareerSummaryIndex--listIndex').addClass('CareerSummaryIndex--cardIndex');
                inputHtml.find('.js-viewToggle[data-view="list"]').removeClass('is-selected');
                inputHtml.find('.js-viewToggle[data-view="grid"]').addClass('is-selected');
            }

        },

        /**
         * Fired on success
         *
         * @param data
         * @param status
         * @param xhr
         * @private
         */
        _success: function (data) {

            var $newResults,
                widget,
                $body;


            widget = this;

            $body = $($.parseHTML(data.match(/<body[^>]*>([\s\S.]*)<\/body>/i)[0]));

            $newResults = $body.find(this.options.selectors.results);

            this._setViewMode($newResults);

            this.resultsEl.empty().html($newResults.html());

            this.resultsEl.trigger('contentcreated');

            this.resultCount = parseInt(this.resultsEl.find('[name="resultCount"]').first().val());

            this.records = this.element.find(this.options.selectors.record);

            $('.FeaturedCareerIndex').trigger('removeFeaturedCareers');

            this._keywordBarTest();
        },

        /**
         * Fired on complete
         * @private
         */
        _complete: function () {
            var currentLoadingIndicator = this.loadingIndicator;

            this.ajaxIsSending = false;

            this.resultsEl.velocity('stop', true).velocity({
                opacity: 1
            }, {
                complete: function() {
                    currentLoadingIndicator.velocity('stop', true).velocity('transition.bounceOut', { duration: 500 });
                }
            });
        },


        _keywordBarTest: function() {
            var widget = this;

            var keywords = this.resultsEl.find('[name="keywords"]').first().val();

            if (keywords === '') {
                this.searchSummaryWrapper.fadeOut(600, function() {
                    widget._updateWayponts();
                });
            } else {
                this.searchSummaryKeywords.text(keywords);
                if (this.resultCount === 1) {
                   this.searchSummaryCount.text(this.resultCount + ' career!');
                } else {
                   this.searchSummaryCount.text(this.resultCount + ' careers!');
                }

                this.searchSummaryWrapper.fadeIn(600, function() {
                    widget._updateWayponts();
                });
            }
        },

        _updateWayponts: function() {
            if (Waypoint) {
                Waypoint.refreshAll();
            }
        }

    });
})
(jQuery, window, document);

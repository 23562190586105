/*global ga:true */
(function ($, window, document, undefined) {
    'use strict';
    /**
     * Checkbox heiracrhcy widget
     */
    $.widget('nsm.searchAutocomplete', {

        /**
         * Widget Options
         */
        options: {
            selectors: {
                jqForm: 'form',
                jqButton: 'button[type="submit"]',
                jqResultsWrapper: '.SearchOverlay-container',
                jqResultsContainer: '.SearchOverlay-results',
                jqResultsContainerContent: '.SearchOverlay-results-content',
                jqInput: '.SearchOverlay-keywordInput',
                jqKeywordsOutput: '.SearchOverlay-allResultsSearchString',
                jqAllResultsLink: '.SearchOverlay-allResultsLink',
                resultsLink: '.js-ajaxSearchResults li a',
                preloader: '.SearchOverlay-preloaders'
            }
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('filteroptions'));

            this.jqForm = this.element.find(this.options.selectors.jqForm);
            this.jqButton = this.element.find(this.options.selectors.jqButton);
            this.jqResultsContainer = this.element.find(this.options.selectors.jqResultsContainer);
            this.jqResultsContainerContent = this.element.find(this.options.selectors.jqResultsContainerContent);
            this.jqInput = this.element.find(this.options.selectors.jqInput);
            this.jqKeywordsOutput = this.element.find(this.options.selectors.jqKeywordsOutput);
            this.jqAllResultsLink = this.element.find(this.options.selectors.jqAllResultsLink);
            this.preloader = this.element.find(this.options.selectors.preloader);
            this.lastSearch = '';
            this.jqXhr = null;
            this.keywords = '';

            var eventHandlers = {};
            eventHandlers['keyup' + this.options.selectors.jqInput] = '_keyInput';
            eventHandlers['click' + this.options.selectors.jqAllResultsLink] = '_goToSearchResults';
            eventHandlers['click' + this.options.selectors.resultsLink] = '_trackCareerClick';
            this._on(eventHandlers);
        },

        _sendSearch: function () {
            // cancel old request if exists

            if (this.ajaxIsSending) {
                this.jqXhr.abort();
            }

            // new XHR object
            this.jqXhr = $.ajax({
                url: '/?ACT=49',
                method: 'POST',
                dataType: 'html',
                data: {
                    keywords: this.keywords,
                    'result_page': '/careers/autocomplete'
                },
                complete: $.proxy(this._updateResults, this)
            });
            this.preloader.stop( true, true ).fadeIn(300);
            this.ajaxIsSending = true;
            this.jqResultsContainer.hide();
        },

        _updateResults: function(data) {
            // don't do anything for aborted requests
            if ('abort' === data.statusText) {
                return;
            }

            var formattedData = $.trim(data.responseText);
            var jqResults = $(formattedData).find('.js-ajaxSearchResults');

            this.preloader.stop( true, true ).fadeOut(300);
            this.jqKeywordsOutput.text(this.keywords);
            this.jqResultsContainerContent.empty();
            this.jqResultsContainerContent.append(jqResults);
            this.jqResultsContainer.show();

            $(this.options.selectors.jqResultsWrapper).addClass('is-showing-results');

            this.ajaxIsSending = false;
            this.lastSearch = this.keywords;

            // no results?
            if (jqResults.length < 1) {

                // Google Event Tracking
                ga('send', {
                    'hitType': 'event', // Required.
                    'eventCategory': 'Site Search - Auto-complete', // Required.
                    'eventAction': 'No Results', // Required.
                    'eventLabel': this.lastSearch
                });

            }
        },

        _keyInput: function() {
            this.keywords = this.jqInput.val() || '';

            if (this.keywords === this.lastSearch) {
                return;
            }

            if (this.keywords.length < 3) {
                this.jqResultsContainer.hide();
                return;
            }

            this._sendSearch();
        },

        _goToSearchResults: function() {
            this.jqForm.submit();
        },

        _trackCareerClick: function(event) {
            var destinationUrl = $(event.currentTarget).attr('href');

            // Google Event Tracking
            ga('send', {
                'hitType': 'event', // Required.
                'eventCategory': 'Site Search - Auto-complete', // Required.
                'eventAction': 'Click', // Required.
                'eventLabel': this.lastSearch + ' - ' + destinationUrl
            });
        }

    });

})($, window, document, undefined);

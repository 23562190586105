/*global jQuery:true, window:true, document:true */

(function ($, window, document, undefined) {
    'use strict';
    /**
     * AJAX Load Widget
     */
    $.widget('nsm.ajaxLoad', {

        /**
         * Widget Options
         */
        options: {},

        /**
         * Create widget
         *
         * @private
         */
        _create: function () {
            // console.log("ajax load bound");
            // Define widget options
            this.options = $.extend(this.options, this.element.data('ajax-load-options'));

            // Define widget properties
            this.url = this.element.data('ajax-load-url') || false;
            this.silent = Boolean(this.element.data('ajax-load-silent')) || false;
            this.completed = false;

            // If we're missing url, return, not creating the widget
            if ( !this.url) {
                return;
            }

            // Listen for child events
            var eventHandlers = {};
            this._on(eventHandlers);

            // Listen for global events
            var globalEventHandlers = {};
            this._on(window, globalEventHandlers);

            this._notify();
        },

        /**
         * The refresh action
         *
         * @private
         */
        _notify: function () {
            var widget = this;

            if (this.completed) {
                return;
            }

            $.ajax({
                url: widget.url,
                type: 'GET',
                success: function (data) {
                    var container;

                    widget.completed = true;

                    if (widget.silent) {

                        return;
                    }

                    container = $('<div></div>').html(data);

                    container.hide();
                    widget.element.append(container);
                    container.fadeIn(800);

                    widget.element.trigger('contentcreated');
                    // console.log("APPENDING");
                    // console.log(data);
                },
                error: function () {}
            });
        }
    });

}(jQuery, window, document));

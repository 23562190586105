/*global docCookies: true, globals: true, Waypoint: true, ga: true */

(function ($, window, document, undefined) {
    'use strict';
    /**
     * Tabs Widget
     */
    $.widget('nsm.randomCareer', {

        /**
         * Widget Options
         */
        options: {
            selectors: {
                modal: '.CareeramajigOverlay',
                closeTrigger: '.js-randomCareerCloseTrigger',
                activateTrigger: '.js-randomCareerActivateTrigger',
                navBar: '.js-randomCareerNav'
            }
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('shareoptions'));
            this.$modal = $(this.options.selectors.modal);
            this.$navBar = $(this.options.selectors.navBar);
            var eventHandlers = {};
            eventHandlers['click ' + this.options.selectors.activateTrigger] = '_handleActivateTriggerClick';
            eventHandlers['click ' + this.options.selectors.closeTrigger] = '_handleCloseTriggerClick';
            this._on(eventHandlers);

            $('html').on('openCareeramajig', $.proxy(this.open, this));
            $('html').on('closeCareeramajig', $.proxy(this.close, this));
        },

        /**
         * Process the search form
         *
         * @param event
         * @private
         */

        _handleActivateTriggerClick: function (event) {
            event.preventDefault();
            this.gotoRandomCareer();
        },

        _handleCloseTriggerClick : function(event) {
            event.preventDefault();
            docCookies.setItem('showCareeramajig', 'false', 3600, '/');
            globals.careeramajigIsVisible = false;
            this.close();
        },

        close : function() {
            $('html').removeClass('is-randomised');
            Waypoint.refreshAll();
            console.log('refreshing waypoints');
        },

        open:  function() {
            $('html').addClass('is-randomised');
            Waypoint.refreshAll();
            console.log('refreshing waypoints');
        },

        gotoRandomCareer : function() {

            globals.careeramajigIsVisible = true;
            docCookies.setItem('showCareeramajig', 'true', 3600, '/');

            this.$modal.fadeIn(300, $.proxy(function() {
                setTimeout($.proxy(this.triggerRandomCareer, this), 1000);
            }, this));

            this.$modal.removeClass('is-hidden');

        },

        triggerRandomCareer : function() {
            $.ajax({
                dataType: 'json',
                url: '/careers/_ajax-random-career',
                success: function (data) {

                    var urls = data.paths.careerUrls;
                    var chosenUrl = urls[Math.floor(Math.random()*urls.length)];

                    // Google not ready yet? exit
                    if (false === (ga.hasOwnProperty('loaded') && ga.loaded === true)) {
                        window.location.href = chosenUrl;
                    }

                    // Google Event Tracking
                    ga('send', {
                        'hitType': 'event', // Required.
                        'eventCategory': 'Random Career', // Required.
                        'eventAction': 'Click', // Required.
                        'eventLabel': chosenUrl,
                        'hitCallback': function() {
                            window.location.href = chosenUrl;
                        }
                    });
                }
            });
        }


    });

})(jQuery, window, document);

/*global ga:true, FB: true */

(function ($, window, document, undefined) {
    'use strict';
    /**
     * Tabs Widget
     */
    $.widget('cp.sharingPane', {

        /**
         * Widget Options
         */
        options: {
            url: '',
            encodedUrl: '',
            facebookShareUrl: 'https://www.facebook.com/sharer/sharer.php',
            twitterShareUrl: 'http://twitter.com/intent/tweet/',
            pinterestShareUrl: 'http://pinterest.com/pin/create/button/',
            sharetype: 'Career',
            image: '',
            widgetLocation: '',
            title: ''
        },

        /**
         * Setup widget (eg. element creation, apply theming, bind events etc.)
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('shareoptions'));

            var eventHandlers = {};
            eventHandlers['click .js-emailFriendLink'] = '_emailSomeone';
            eventHandlers['click .js-facebookShareLink'] = '_shareFacebook';
            eventHandlers['click .js-pinterestShareLink'] = '_sharePinterest';
            eventHandlers['click .js-twitterShareLink'] = '_shareTwitter';
            this._on(eventHandlers);
        },

        /**
         * Process the search form
         *
         * @param event
         * @private
         */
        _emailSomeone: function (event) {
            event.preventDefault();
            var locationUrl = window.location.href,
                emailSubject,
                emailBody,
                mailString;

            // Track event in Google Analytics
            ga('send', {
                'hitType': 'social',
                'socialNetwork': 'Email',
                'socialAction': 'Send',
                'socialTarget': locationUrl,
                'page': locationUrl
            });

            emailSubject = encodeURI($('.js-emailFriendLink').attr('data-share-subject'));
            emailBody = encodeURI($('.js-emailFriendLink').attr('data-share-body') + '\n\n' + locationUrl);
            mailString = 'mailto:?subject='+emailSubject+'&body='+emailBody;

            // this forces the browser to react to the mailto: protocol
            window.location.href = mailString;
        },

        _shareFacebook: function (event) {
            event.preventDefault();
            var locationUrl = window.location.href;
                //actionName = 'Share' + (this.options.widgetLocation ? ' - ' + this.options.widgetLocation : '');

            // no facebook? exit
            if (!FB) {
                // console.log('Facebook SDK not available');
            }

            FB.ui({
                method: 'share',
                href: locationUrl
            }, function (response) {
                /*jshint camelcase: false */
                if (response && !response.error_code) {
                    // Track event in Google Analytics
                    ga('send', {
                        'hitType': 'social',
                        'socialNetwork': 'Facebook',
                        'socialAction': 'Share',
                        'socialTarget': locationUrl,
                        'page': locationUrl
                    });
                } else {
                    // do nothing
                }
            });

        },

        _sharePinterest: function (event) {
            event.preventDefault();
            var locationUrl = window.location.href,
                shareString,
                imgAtt;
                //actionName = 'Share' + (this.options.widgetLocation ? ' - ' + this.options.widgetLocation : '');

            // Track event in Google Analytics
            ga('send', {
                'hitType': 'social',
                'socialNetwork': 'Pinterest',
                'socialAction': 'Share',
                'socialTarget': locationUrl,
                'page': locationUrl
            });

            shareString = this.options.pinterestShareUrl + '?url=' + encodeURIComponent(locationUrl) + '&description=' + encodeURI('Check out this ' + this.options.sharetype);
            imgAtt = '&media=' +  encodeURIComponent(this.options.image);
            shareString = shareString.concat(imgAtt);
            window.open(shareString, '_blank', 'location=yes,height=330,width=760,scrollbars=yes,status=no');
        },

        _shareTwitter: function (event) {
            event.preventDefault();
            var shareString,
                shareMessage,
                locationUrl = window.location.href;
                //actionName = 'Share' + (this.options.widgetLocation ? ' - ' + this.options.widgetLocation : '');

            // Track event in Google Analytics
            ga('send', {
                'hitType': 'social',
                'socialNetwork': 'Twitter',
                'socialAction': 'Tweet',
                'socialTarget': locationUrl,
                'page': locationUrl
            });

            // prep the share via twitter URL
            shareMessage = encodeURI($('.js-twitterShareLink').attr('data-share-message'));
            shareString = this.options.twitterShareUrl + '?text=' + shareMessage + '&amp;button_hashtag=mybigtomorrow&amp;url=' + encodeURIComponent(locationUrl);

            // open the share via twitter in a new window
            window.open(shareString, '_blank', 'location=yes,height=440,width=550,scrollbars=yes,status=no');
        }

    });

})(jQuery, window, document);
